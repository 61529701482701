
import ARES1 from './abis/ARES1.json';
import ARES2 from './abis/ARES2.json';
import ARES3 from './abis/ARES3.json';
import ARES4 from './abis/ARES4.json';


export const isdABIMap ={
    0: ARES1,
  105: ARES2,
  106: ARES3,
  301: ARES3,
  106: ARES4
};


export const isdStatusCode = {
  unLocked: 0,
  stored: 1,
  storedInspect: 2,
  unStoredInspect: 3,
  unStored: 4,
  verified: 5,
  forSale: 6,
  onChain: 7,
  stage: 8,
  lastSale: 9
};


export const isdCapsCode = {
  status: 0,
  meta:   1,
  store:  2,
  inspect: 3,
  verify: 4,
  offer:  5,
  buy:    6,
  burn:   7,
  own:    8
};

export const isdAccessCode = {
   oneOf: 0,
   master: 1,
   admin:  2,
   chief:  3
}


export const isdStageCode = {
  init: 0,
  inStored: 1,
  inStoreInspected: 2,
  unStoreInspected: 3,
  unStored: 4
}

export const isdStageText = {
  0: "tokenizing...",
  1: "stored",
  2: "tradable",
  3: "unstore",
  4: "out"
}

export const isdAccessManagerCode = {
  royalty: 0,
  store: 1,
  expert: 2,
  inspect: 3
}


export const isdCurrency = {
  "0x0000000000000000000000000000000000000000": { name: "ETH", decimals: 18, chain: 0 },
  "0xdAC17F958D2ee523a2206206994597C13D831ec7": { name: "USDT",decimals:  6, chain: 1 },
  "0xE4b70F9DdBb286f3cFA836E0fD970479f3193252": { name: "USDZ",decimals: 10, chain: 1 }, 
  "0x9e41d61e40eD9525becf90ce0a2B65E375699E7e": { name: "ARES",decimals: 10, chain: 1 },
  "0x6B175474E89094C44Da98b954EedeAC495271d0F": { name: "DAI", decimals:  18, chain: 1 },
//  "0x0000000000000000000000000000000000000000": { name: "ETH", decimals: 18, chain: 4 },
  "0xf72C1fC27265DA10139531eaBf9D3fb4e8bD1F4D": { name: "USDT",decimals:  6, chain: 4 },
  "0x8672B5363Fbb0055F317CBC309751c6198b1FC81": { name: "ROCK",decimals: 10, chain: 4 },
  "0x95ACad22a873F4048c5B6043F80809920299651F": { name: "DAI", decimals:  18, chain: 4 },
//  "0x0000000000000000000000000000000000000000": { name: "ETH", decimals: 18, chain: 5 },

  "0x0DaEBb0941E09374B45675374aa24023B461360E": { name: "USDZ",decimals: 10, chain: 5 }, 
  "0x5375EC7492374e2B67BA295506eF38D69C8f992b": { name: "USDT",decimals:  6, chain: 5 },
  "0x6F02B168080507F6d85Ddbb2EBD2460bF8651DFf": { name: "ARES",decimals: 10, chain: 5 },

  "0xFb14078e9B374dD1BD9f7e4f649DDb200D9828DF": { name: "USDT",decimals: 6, chain: 11155111 },
  
  "0xc50b1dCf2577b82AE595035C5eE1870c57399816": { name: "USDZ", decimals:  10, chain: 11155111 },
  "0xE6679B2b62186b09644599C529526c59586A6a0E": { name: "USDZ_", decimals:  10, chain: 11155111 },
  "0x57862D592a48104a14a17459eF3472A94697c7d8": { name: "USDZ_", decimals:  10, chain: 11155111 },
  "0x1A085DCBAd35DA7164aFa13F0228eC253AFac9a3": { name: "ARES",decimals: 10, chain: 11155111 },
  "0xEAab803826b045C4934938c2935dd2Bb836917cB": { name: "DAI", decimals:  18, chain: 11155111 }
}


export const isdNullAddress = '0x0000000000000000000000000000000000000000';

export const isdNullPrice = { price: 0, currency: '0x0000000000000000000000000000000000000000', decimals: 18};

export const isdTokenSwapId = '0x98f6200e';

export const isdContractMap = {
   "default": {    1: '0xebffF332ee65510506529b43B6e70e61A60233b6',   5: '0xcd146cC2296D78b7Ff6a15fa1ed21E30fF5B2C7e', 11155111: '0x3c2A6Ce60d1E58b19401F3B3A627d9731BFf2eF0' },
//  "default": {    1: '0xAC98aa0b2b1e18088c46A77460Abd7a495258D9a',   4: '0xED73963A20c54aD3286171047D169e6731f71D5E', 5: '0xE6679B2b62186b09644599C529526c59586A6a0E' },
          1: {    1: '0xAC98aa0b2b1e18088c46A77460Abd7a495258D9a',   4: '0xED73963A20c54aD3286171047D169e6731f71D5E', 5: '0xE6679B2b62186b09644599C529526c59586A6a0E' },
          2: {    1: '0x953B18054EEC3F43E7E435ef9bDfA47e921ACE44',   5: '0x1cC1b292f3337AE37aE22050DB541e952456850E' },   
          3: {    1: '0xebffF332ee65510506529b43B6e70e61A60233b6',   5: '0xcd146cC2296D78b7Ff6a15fa1ed21E30fF5B2C7e', 11155111: '0x3c2A6Ce60d1E58b19401F3B3A627d9731BFf2eF0' },
          4: {    1: '0xBBf99191860471db4D063255fC4a2cb894B80b57',   5: '0x9F81d01a98ADdD8233696A8deEA3c729980c1616'}
}
