import React, { useState } from 'react';
import classes from './Dig.module.css';
import digIcon from '../assets/stake.png';



const Dig = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {
    event.preventDefault();

    let i = parseInt(event.target.value);


    if (i === 0) {
      setInputValue("");
    } else {
      setInputValue(""+i);
    }
    props.inputHandler(i);

  };

  const goMax = () => {
    const plus = 5; // %
    const gas = 600000; //439182
    const costs = props.gasPrice * gas * ((100 + plus)/100) /  10**(18-9);

    let i = Math.floor( (props.userBalance - costs) /  (props.price / 10**18));
    console.log("gomax",props.gasPrice,gas, plus, costs, props.userBalance);

    if (i < 1) {
       i = 0;
       setInputValue("");
     } else{
       setInputValue(""+i);
     }


    props.inputHandler(i);
//    props.inputHandler(props.userBalance);
  };

  const etherScan = ( networkId ) => {
    if (networkId === 5) {
      return "https://goerli.etherscan.io/address/";
    }
    if (networkId === 4) {
      return "https://rinkeby.etherscan.io/address/";
    }
    if (networkId === 1) {
      return "https://etherscan.io/address/";
    }
  }

  if (! props.contract) {

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <h1> connecting ... </h1>
        </div>
      </div>
    );

  }


  return (
    <div className={classes.Info}>

      <h5 onClick={goMax} className={classes.goMax}>
        Your balance: { Math.floor(props.userBalance * 1000)/1000 } ETH
        &nbsp;
      </h5>


      <div className={classes.inputDiv}>
        <input
          placeholder="How many new SNFTS?"
          className={classes.input}
          type="number"
          min="0"
          step="1"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.digButton}
        onClick={() => {
          props.actionHandler();
          setInputValue('');
        }}
      >
        <img src={digIcon} alt="dig icon" className={classes.digIcon} />
        <p>{ inputValue === "" ? "Please enter amount" : "Dig" }</p>
      </button>


      <div className={classes.box}>
        <p>Digging Fee:  {(props.price / 10**18) + " ETH per SNFT"}</p>
        <p>Next Token ID:  { props.info.totals ? parseInt(props.info.totals) + 1 : "n/a"}</p>
      </div>
      <br/>
      <br/>

      <a href={"/?/" + props.contract._address + ":wallet"}>
      <button
        className={classes.digButton}
      >
        <p style={{position: "static"}}>Gallery</p>
      </button>
      </a>


      <br/>
      <br/>

      <div className={classes.box}>
        <p> wallet: <b><a href={props.network.id ? etherScan(props.network.id) + props.account : ""} target="scan"  rel="noopener noreferrer" >{props.account}</a></b> ({"<-Click"})</p>
        <p> contract: <b><a href={props.network.id ? etherScan(props.network.id) + props.contract._address : ""} target="scan"  rel="noopener noreferrer" >{props.contract._address}</a></b> ({"<-Click"})</p>
        <p> network: <b>{props.network.name}</b> (id: <b>{props.network.id}</b>)</p>
      </div>

    </div>
  );
};

export default Dig;
