import React, { useState } from 'react';
import classes from './Navigation.module.css';

import { isdCapsCode,  isdStatusCode, isdAccessCode } from '../isd';


import Tabs from "./Tabs";


const Item = ( props ) => {
  return "";
}


const Navigation = (props) => {
  const [poolStatus, setPoolStatus] = useState('default');

//  console.log("##NAV##",props);

  const changeModul = (val) => {
    if (val === poolStatus) {
    } else {
      setPoolStatus(val);
      props.changePage();
    }
  };

  const displayMessage = () => {
    if (window.ethereum)  {
      if (props.contractAddress) {
        if ( props.metaLoaded ) {
          return props.children[0];
        }
        return "meta is loading ...";
      }
      return "contract is loading ...";
    }
    return  "connect your wallet!" ;
  }

  return (
     <Tabs className={classes.navigation}>
        <Item label='SNFT'  enable={true} >
          { displayMessage() }
        </Item>
        <Item label='Asset'  enable={true} >
          {(window.ethereum /*&& window.ethereum.selectedAddress*/) ? ( props.metaLoaded ? props.children[1] : "meta is loading ..." ) : "connect your wallet" }
        </Item>
        <Item label='Meta' name='setup' enable={ props.caps[ isdCapsCode.meta ] || props.access.admin[ isdAccessCode.oneOf] } poolStatus={poolStatus} changeModul={changeModul}>
         {props.metaLoaded ? (  props.status.flags[isdStatusCode.unLocked] ? "editing not allowed when unlocked " : props.children[2]) : "meta is loading ..."}
        </Item>
        <Item label='Store' name='instore' enable={ props.caps[ isdCapsCode.store ] ||  props.caps[ isdCapsCode.inspect ]  || props.access.admin[ isdAccessCode.oneOf]} poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[3]}
        </Item>
        <Item label='Expert' name='expert' enable={ props.caps[ isdCapsCode.verify ] || props.access.admin[ isdAccessCode.oneOf]} poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[5]}
        </Item>
        <Item label='Offer' name='offer' enable={ props.caps[ isdCapsCode.own ] || props.access.admin[ isdAccessCode.oneOf]} poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[6]}
        </Item>
        <Item label='Buy' name='buy' enable={  props.status.flags[ isdStatusCode.forSale ] && (props.caps[ isdCapsCode.buy ]  || props.access.admin[ isdAccessCode.oneOf] )} poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[7]}
        </Item>
        <Item label='UnStore' name='unstore' enable={ props.caps[ isdCapsCode.store ] ||  props.caps[ isdCapsCode.inspect ] || props.access.admin[ isdAccessCode.oneOf]} poolStatus={poolStatus} changeModul={changeModul}>
          {props.children[4]}
        </Item>
        <Item label='*' name='info' enable={ true } poolStatus={poolStatus} changeModul={changeModul}>
          {(window.ethereum /*&& window.ethereum.selectedAddress*/) ?  props.children[8] : "connect your wallet" }
        </Item>
        <Item label='#' name='wallet' enable={ true } poolStatus={poolStatus} changeModul={changeModul} >
        </Item>
      </Tabs>
  )

/*
  return (
    <div className={classes.navigation}>
      <div label="Status"
        className={
          poolStatus === 'default'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('default');
        }}
      >
        Status.Public
      </div>
      <div label="Status"
        className={
          poolStatus === 'setup'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('setup');
        }}
      >
        Set Meta
      </div>
      <div label="Status"
        className={
          poolStatus === 'instore'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('instore');
        }}
      >
        Store
      </div>
      <div label="Status"
        className={
          poolStatus === 'unstore'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('unstore');
        }}
      >
        Unstore
      </div>
      <div label="Status"
        className={
          poolStatus === 'expert'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('expert');
        }}
      >
        Expert
      </div>
      <div label="Status"
        className={
          poolStatus === 'offer'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('offer');
        }}
      >
        Offer
      </div>
      <div label="Status"
        className={
          poolStatus === 'buy'
            ? classes.buttonActive
            : classes.buttonNonActive
        }
        onClick={() => {
          changeModul('buy');
        }}
      >
        Buy.Public
      </div>
    </div>
  );
  */

};

export default Navigation;
