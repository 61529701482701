
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

import base64 from 'react-native-base64'
import md5 from 'js-md5'

//import { AiOutlineProfile, AiOutlineFileImage, AiOutlineVideoCamera } from "react-icons/ai";

//import { IconContext } from "react-icons";



// styles
import classes from './App.module.css';


// abis now

import ERC20 from '../src/abis/ERC20.json';
import TokenSwap from '../src/abis/TokenSwap.json';
import Versioning from '../src/abis/Versioning.json';

// components
import Navigation from './components/Navigation';
import Wallet from './components/Wallet';
import All from './components/All';
import Profile from './components/Profile';
import Profile721 from './components/Profile721';
import Meta from './components/Meta';
import InStore from './components/InStore';
import OutStore from './components/OutStore';
import Expert  from './components/Expert';
import Offer  from './components/Offer';
import Buy  from './components/Buy';
import Info from './components/Info';
import Dig from './components/Dig';

import Start from './components/Start';

import { isdNullPrice, isdTokenSwapId, isdContractMap, isdABIMap } from './isd';

//import AdminTesting from './components/AdminTesting';

import icon from './assets/icon.png';
import icon2 from './assets/icon2.png';








const App = ( props ) => {

//  console.log("app props",props);
const chainId = 1;


let parts = window.location.href.split('/').pop().replace(/[#?]*/,"").split(":");

console.log(parts);
let tokenId = parseInt(parts[1]);
if (isNaN(tokenId))
  tokenId = 0;





const [chainChangeCounter, setChainChangeCounter] = useState(0);


  const [account, setAccount] = useState('Connecting to Metamask..');
  const [network, setNetwork] = useState({ id: '0', name: 'none' });
  const [gasPrice, setGasPrice] = useState( 40 * 10**(18-9));


  const [contractAddress, setContractAddress] = useState(0);

  const [appStatus, setAppStatus] = useState(true);
  const [loader, setLoader] = useState(false);
  const [metaLoaded, setMetaLoaded] = useState(false);
  const [exists, setExists] = useState(false);
  const [page, setPage] = useState(1);

  const [isdTokenContract, setisdTokenContract] = useState(null);
  const [isdStatus, setisdStatus] = useState({ flags: [], dates: [], codes: []});
  const [isdFees, setisdFees] = useState({
      denominator: '0',
      royalties: [{ account: '0x0', value: '0'}],
      market: { account: '0x0', value: '0' },
      firstSale: { account: '0x0', value: '0' }
    });

  const [isdMeta, setisdMeta] = useState('');
  const [isdCaps, setisdCaps] = useState('');
//  const [isdAccess, setisdAccess] = useState('');
  const [isdPriceLast, setisdPriceLast] = useState(isdNullPrice);
  const [isdPriceOffer, setisdPriceOffer] = useState(isdNullPrice);

  const [isdMetaURI, setisdMetaURI] = useState('');
  const [isdMetaCheckSum, setisdMetaCheckSum] = useState({ token: '0x0', calc: '0x0'});
  const [isdContractInfo, setisdContractInfo] = useState('');
  const [isdAccess, setisdAccess] = useState({ admin: [], manager:[] });

  const [isdDigPrice, setisdDigPrice] = useState(0);

  const [isdTokenList, setisdTokenList] = useState({ items:[] });

  const [isdAllList, setisdAllList] = useState({ items:[] });

  const [inputPrice, setInputPrice] = useState({ price: 0, currency: "0x0", decimals: "18", from: 0, till: 0 });
  const [inputDig, setInputDig] = useState('');

  const [inputMeta, setInputMeta] = useState('');
  const [inputDelegateStore, setInputDelegateStore] = useState('');

  const [userBalance, setUserBalance] = useState('0');
  const [isdTransferable, setisdTransferable] = useState(false);



  useEffect(() => {
    //connecting to ethereum blockchain
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };

    ethEnabled();
  }, []);


if (window.ethereum && !window.changesRegisterd) {

  window.ethereum.on('accountsChanged', function (accounts) {
    // Time to reload your interface with accounts[0]!
    console.log(accounts);

    fetchDataFromBlockchain();
  });


  window.ethereum.on('networkChanged', function (networkId) {
    // Time to reload your interface with the new networkId
    console.log(networkId);

    fetchDataFromBlockchain();
  });

  window.changesRegisterd = 1;
}



  const getRandomStr = () => Math.random().toString(36).slice(2);



  // get tokenid from URI
  const href = window.location.href;
  console.log('ÖÖÖÖ',href.split('/'),"X",href.split('/').pop(),"X",href.split('/').pop().replace(/[#?]*/,""),"ÜÜÜ");


//  console.log("app init :",tokenId);


 const getContractAddress = (networkId) => {

   let collectionId = parseInt(parts[0]);
   console.log("collection",parts,collectionId,isdContractMap);

    if (parts[0].substring(0,2) == "0x") {
      console.log("collection direct mode", parts[0]);
      return parts[0];
    }

    if (collectionId == 0 || isNaN(collectionId)) {
      return isdContractMap["default"][networkId];
    }

    // OK
    if (isdContractMap[collectionId] && isdContractMap[collectionId][networkId] )
      return isdContractMap[collectionId][networkId];


    return 0;
 }

 const loadMetaData = async ( isdToken, tokenId ) => {

     //  fetching balance of Testtoken and storing in state
     let metaURI = await isdToken.methods
       .tokenURI(tokenId)
       .call();

     console.log("got metaURI: ",metaURI);

     //  fetching balance of Testtoken and storing in state
     let metaCheckSumToken = await isdToken.methods
       .tokenCheckSum(tokenId)
       .call();

     console.log("got metaCheckSum: ",metaCheckSumToken);

     setisdMetaURI(metaURI);


     let sig = 'data:application/json;base64';

     if (metaURI.startsWith(sig) ) {

         var data = metaURI.substring(sig.length+1);
         //console.log("got dat from blob:",data);
         var metatext = base64.decode(data);

         const metaCheckSumCalc = "0x" + md5.hex_md5(metatext);

         //console.log("got txt from blob:",metatext);
         var meta = JSON.parse( metatext );
         //console.log("got meta from blob:",meta);

         // for internal use
         setisdMeta(meta);

         // for editor
         setInputMeta(JSON.stringify(meta.properties, null, 2) );

         setisdMetaCheckSum({ token: metaCheckSumToken, calc: metaCheckSumCalc});

         // for both
         setMetaLoaded(true);


     } else {

   		var xhr = new XMLHttpRequest();
   		xhr.open("GET", metaURI + '?' + getRandomStr(1000000,9999999), true);
           xhr.onreadystatechange = function() {
               if(xhr.readyState === 4 && xhr.status === 200) {

                 var metatext = xhr.responseText;
                 console.log("got meta:",metatext);

                 const metaCheckSumCalc = "0x" + md5(metatext);


         				var meta = JSON.parse(metatext);
                 console.log("got meta from ajax:",meta,metaCheckSumCalc);


                 meta.image = meta.image     + '?' + getRandomStr(1000000,9999999);
                 meta.preview = meta.preview + '?' + getRandomStr(1000000,9999999);

                 // for internal use
                 setisdMeta(meta);


                 // for editor
                 setInputMeta(JSON.stringify(meta.properties, null, 2) );

                 setisdMetaCheckSum({ token: metaCheckSumToken, calc: metaCheckSumCalc});

                 // for both
                 setMetaLoaded(true);
         			}
     		}
   		xhr.send();
     }
  }



    const changeNetwork = async ( props ) => {

     console.log("change of network requested cnt:",chainChangeCounter);

     setChainChangeCounter( chainChangeCounter + 1 );

     if (window.ethereum) {

       window.web3 = new Web3(window.ethereum);

       try {
         await window.ethereum.request({
         method: 'wallet_switchEthereumChain',
           params: [{ chainId: window.web3.utils.toHex(props.chainId) }],
         });

         //window.location.reload();


         fetchDataFromBlockchain();
  /*
         if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))
          fetchDataFromBlockchain();
         else
          window.location.reload();
  */
       } catch (error) {
         console.error(error);
       }
     }
   }

   const getGasPrice = async ( props ) => {

     window.web3 = new Web3(window.ethereum);

     const overhead = "2";
 /*
     if (props.overhead ) {
       overhead = props.overhead;
     }
     */
     const gasPriceOverhead = 1.1;
     const gasPriceTip = 2  * 10**9;
     const gasPrice_ = await window.web3.eth.getGasPrice();
     const networkId = await window.web3.eth.net.getId();

     if (networkId === 1 || networkId === 5  || networkId === 11155111) {
       let gasIdea = (   Math.round(parseInt(gasPrice_)  * gasPriceOverhead) + gasPriceTip ).toString();
       console.log("gasprice:",gasIdea,gasPrice_, gasPriceOverhead, window.web3.utils.toWei('40','gwei'));
       setGasPrice(gasPrice_);
       return gasIdea;
     }

     return window.web3.utils.toWei('40', 'gwei');
  }

   const getGasPrice_ = async ( props ) => {

     window.web3 = new Web3(window.ethereum);

     const gasPriceOverhead = window.web3.utils.toWei('2', 'gwei');
     const gasPrice_ = await window.web3.eth.getGasPrice();
     const networkId = await window.web3.eth.net.getId();

     if (networkId == 1) {
       setGasPrice(gasPrice_);
       return gasPrice_ + gasPriceOverhead;
     }

     return window.web3.utils.toWei('22', 'gwei');
  }


  const fetchDataFromBlockchain = async () => {

    if ( window.ethereum ) {


      console.log("chain",window.ethereum.networkVersion, chainId);
      /*
      if (window.ethereum.networkVersion != chainId) {
        changeNetwork({ chainId: chainId });

        return;
      }
      */

      console.log("eth checked");

      await window.ethereum.request({ method: 'eth_requestAccounts' });


      //connecting to metamask
      window.web3 = new Web3(window.ethereum);
      let web3 = window.web3;

      console.log(web3);


      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);


      //loading users network ID and name
      const networkId = await web3.eth.net.getId();
      const networkType = await web3.eth.net.getNetworkType();
      setNetwork({ ...network, id: networkId, name: networkType });

      const gasPrice_ = await web3.eth.getGasPrice();
      if (networkId == 1 ) {
        setGasPrice(gasPrice_);
      }

      console.log("x2");

      const result = await web3.eth.getBalance(accounts[0]);
      setUserBalance(web3.utils.fromWei(result,"ether"));


      const _contractAddress = getContractAddress(networkId);
      console.log("using contract address:",_contractAddress);
      setContractAddress(_contractAddress);

      if (_contractAddress) {

        const versionToken = new web3.eth.Contract( Versioning.abi, _contractAddress );
        let version = 0;
        try {
          version = await versionToken.methods.version().call();

        } catch (e) {
          console.log("contract version missing",e);
          version = 0;

          // FIXME: seamless migration
          if (networkId == 4) version = 105;
        }

        console.log("using contract version:",version);

        if (!isdABIMap[version]) {
          console.log("FATAL: no ABI found for ", version);
          return;
        }

        const CONTRACT = isdABIMap[version];
        console.log("using contract abi: ", CONTRACT.contractName);
/*
      const isdTokenData = CONTRACT.networks[networkId];
      if (isdTokenData) {
        let web3 = window.web3;
        */
        const isdToken = new web3.eth.Contract(
          CONTRACT.abi,
          _contractAddress //isdTokenData.address
        );

        setisdTokenContract(isdToken);


        console.log("x3 :",tokenId,":");


        // TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON TEST ON

        let ex = false

        // we do not check tokenId == 0  => never exists
        if (tokenId > 0) {
          ex = await isdToken.methods
            .exists(tokenId)
            .call();
        }
        setExists(ex);

        console.log("x4",exists);

        let aw = [];

        if (ex) {


          loadMetaData( isdToken, tokenId );


          //  fetching balance of Testtoken and storing in state
          let priceOffer = await isdToken.methods
            .getOffer(tokenId)
            .call();

          setisdPriceOffer(priceOffer);
          console.log("o:",priceOffer);

          let priceLast = await isdToken.methods
            .getPrice(tokenId)
            .call();

          setisdPriceLast(priceLast);
          console.log("p:",priceLast);

          let fees = {
            denominator: await isdToken.methods.feeDenominator().call(),
            royalties: await isdToken.methods.getRoyalities(tokenId).call(),
            firstSale: await isdToken.methods.getFirstSaleFee(tokenId).call(),
            market: await isdToken.methods.getMarketFee().call()
          }
          setisdFees(fees);
          console.log(fees);


          //  fetching balance of Testtoken and storing in state
          let statusTimeStamp = await isdToken.methods
            .getStatus(tokenId)
            .call();

          let statusFlags = [];
          let statusDates = [];
          let statusCodes = [];
          statusTimeStamp.forEach((s, i) => {
            statusCodes.push(s);
            if (parseInt(s) === 0) {
              statusDates.push("n/a");
              statusFlags.push(false);
            } else {
              let dt = new Date(s * 1000);
              statusDates.push(
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
              statusFlags.push(true);
            }
          });

          setisdStatus({flags: statusFlags, dates: statusDates, codes: statusCodes });
          console.log(statusFlags,statusDates);


          //  fetching balance of Testtoken and storing in state
          let caps = await isdToken.methods
            .getCaps(tokenId, accounts[0])
            .call();

          setisdCaps(caps);
          console.log(caps);


          // TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF TEST OFF

          //  fetching balance of Testtoken and storing in state
          aw = await isdToken.methods
            .getManagerWalletsToken(tokenId)
            .call();

          console.log(aw);

        }// ex


        //  fetching balance of Testtoken and storing in state
        let access = await isdToken.methods
          .getAccess(accounts[0])
          .call();

        console.log(access);

        setisdAccess({ admin: access, manager: aw});




        //  fetching balance of Testtoken and storing in state
        let contractInfo = {
            id: tokenId,
            totals: await isdToken.methods.totalSupply().call(),
//            designedBy: await isdToken.methods.designedBy().call(),
            deployedBy: await isdToken.methods.deployedBy().call(),
//            certifiedBy: await isdToken.methods.certifiedBy().call(),
  //          assetTokenClass: await isdToken.methods.assetTokenClass().call(),
            specVersion: await isdToken.methods.specVersion().call(),
            decimals: await isdToken.methods.decimals().call(),
            image: await isdToken.methods.image().call(),
            baseURI: await isdToken.methods.baseURI().call(),
            symbol: await isdToken.methods.symbol().call(),
            name: await isdToken.methods.name().call(),
            ver: version
        }

        setisdContractInfo(contractInfo);
        console.log(contractInfo);


        let isTransferable = true;
        try {
          isTransferable = await isdToken.methods
            .isTransferable( tokenId )
            .call();
        } catch (e) {
          console.log("support transferable missing",e);
        }
        console.log("transferable:",isTransferable);
        setisdTransferable( isTransferable );


        let digPrice = await isdToken.methods
          .digPrice()
          .call();

          setisdDigPrice(digPrice);


        if ( tokenId > contractInfo.totals) {
            console.log("tokenId out of range",tokenId)

      //      setAccount('tokenId out of Range');
            setLoader(false);
            return;
        }


        if (tokenId === 0) {
          let tokenList=[];

          //  [ '0xa355f7D73C17fbD3c17221c24FbBD3D53bCDEAa0', '0xbCaa9aF7d7519daC235a74574F8CaF7372CfA566' ].forEach((item, i) => {

          let x = window.location.href.split("-");
          if (x.length>1) {
            updateAllList(isdToken,x[1]);
          }

          //  fetching balance of Testtoken and storing in state
          try {
            tokenList = await isdToken.methods
              .getTokenListByOwner( accounts[0])
              .call();
          } catch (e) {
            console.log("tokenlist problem",e);

            // hotfix
            if (networkId == 1 && _contractAddress == '0xAC98aa0b2b1e18088c46A77460Abd7a495258D9a') {
              tokenList = [
                {
                "id": 1,
                "URI": "ares/one/1"
                }
              ];
            }

/*
            if (networkId == 5 && contractAddress == '0x1cC1b292f3337AE37aE22050DB541e952456850E') {
              tokenList = [
                {
                "id": 1,
                "URI": "ares/duo/1"
                }
              ];
            }
            */
          }
          setisdTokenList({ items: tokenList });
          console.log("list:",tokenList);
        }

        // contractAddress not found
      }   else {

        console.log("contract not registered");
        setAccount('Contract not registered');
        setLoader(false);

      }


      //removing loader
      setLoader(false);
    } else if (!window.web3) {

      console.log("no ethereum available");
      setAppStatus(false);

      setAccount('Metamask is not detected');
      setLoader(false);
    } else {

      setAccount('Miau');
      setLoader(false);
    }
  };




  const inputMetaHandler = (received) => {
    setInputMeta(received);
  };


  const inputPriceHandler = (received) => {
    setInputPrice( inputPrice => ({ ...inputPrice,
       price: received.price,
       currency: received.currency,
       decimals: received.decimals,
       from: received.from,
       till: received.till
    }));
    console.log("read price: new", received);
    console.log("read price: old", inputPrice);
  };

  const inputDelegateHandler = (received) => {
    setInputDelegateStore(received);
  };



  const changePage = () => {
    if (page === 1) {
      setPage(2);
    } else if (page === 2) {
      setPage(1);
    }
  };




  const isdSetTransferable = async (checked) => {
    if (!appStatus) {
    } else {
      setLoader(true);

      console.log("set transferable:",checked," for TokenId ",tokenId);

      isdTokenContract.methods
        .setTransferable(tokenId, checked)
        .send({
          from: account,
      //    value: window.web3.utils.toHex(window.web3.utils.toWei( e, 'ether')),
          gasLimit: window.web3.utils.toHex(200000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };


  const isdStore = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);

      let e = isdMeta.properties.notes.storage;
      console.log(e);

      isdTokenContract.methods
        .store(tokenId)
        .send({
          from: account,
          value: window.web3.utils.toHex(window.web3.utils.toWei( e, 'ether')),
          gasLimit: window.web3.utils.toHex(200000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };

  const isdStoreInspect = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);
      isdTokenContract.methods
        .storeInspect(tokenId)
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };

  const isdUnStoreInspect = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);
      isdTokenContract.methods
        .unStoreInspect(tokenId)
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };

  const isdUnStore = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);
      isdTokenContract.methods
        .unStore(tokenId)
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };

  const isdVerify = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);
      isdTokenContract.methods
        .verify(tokenId)
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };

  const isdSetPrice = async () => {
    if (!appStatus) {
    } else {
      if (!inputPrice || inputPrice.price === '0' || inputPrice.price < 0) {
        console.log("clear price");
        setInputPrice( isdNullPrice );
      } else {
        setLoader(true);
        console.log("set offer/price",
           tokenId,
           inputPrice,
           inputPrice.price * ( 10**inputPrice.decimals),
           window.web3.utils.toBN(inputPrice.price * ( 10**inputPrice.decimals) ),
        );
        console.log("ip:",inputPrice);
        console.log("a:",isdTokenContract._address);

        //let convertToWei = window.web3.utils.toWei(inputPrice, 'Ether');

        //aproving tokens for spending
        isdTokenContract.methods
          .setOffer(
            tokenId,
            inputPrice.currency, window.web3.utils.
            toBN(inputPrice.price * ( 10**inputPrice.decimals) ),
            inputPrice.from,
            inputPrice.till
          )
          .send({
            from: account,
            gasLimit: window.web3.utils.toHex(180000),
            gasPrice: window.web3.utils.toHex(await getGasPrice())
          })
          .on('transactionHash', (hash) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('receipt', (receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('confirmation', (confirmationNumber, receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();

            console.log("clear price");
            setInputPrice(isdNullPrice);
          })
          .on('error', function(error) {
            setLoader(false);
            console.log('Error Code:', error.code);
            console.log(error.message);
          });

      }
    }
  };

  const isdDelPrice = async () => {
    if (!appStatus) {
    } else {
        setLoader(true);

        //let convertToWei = window.web3.utils.toWei(inputPrice, 'Ether');

        //aproving tokens for spending
        isdTokenContract.methods
          .delOffer(tokenId)
          .send({
            from: account,
            gasLimit: window.web3.utils.toHex(80000),
            gasPrice: window.web3.utils.toHex(await getGasPrice())
          })
          .on('transactionHash', (hash) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('receipt', (receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('confirmation', (confirmationNumber, receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('error', function(error) {
            setLoader(false);
            console.log('Error Code:', error.code);
            console.log(error.message);
          });

        console.log("clear price");
        setInputPrice(isdNullPrice);
    }
  };







  const isdMarketBuy = async ( bid ) => {

    let web3 = window.web3;

    const coinToken = new web3.eth.Contract(
      ERC20.abi,
      isdPriceOffer.currency
    );

   const gp = await getGasPrice();

   let price = isdPriceOffer.price;
   if (bid>0) {
     price = bid;
   }
   coinToken.methods
    .approve( isdTokenContract._address, price  )
    .send({
      from: account,
      gasLimit: window.web3.utils.toHex(220000),
      gasPrice: window.web3.utils.toHex(gp)
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      console.log("approval confirmed");

    })
    .on('transactionHash', (hash) => {
      console.log("approval tx");

            console.log("wasp ", tokenId);
            isdTokenContract.methods
              .paws( account, tokenId, price, 0 )
              .send({
                from: account,
                gasLimit: window.web3.utils.toHex(400000),
                gasPrice: window.web3.utils.toHex(gp)
              })
              .on('transactionHash', (hash) => {
                setLoader(false);
                fetchDataFromBlockchain();
              })
              .on('receipt', (receipt) => {
                setLoader(false);
                fetchDataFromBlockchain();
              })
              .on('confirmation', (confirmationNumber, receipt) => {
                setLoader(false);
                fetchDataFromBlockchain();
              })
              .on('error', function(error) {
                console.log('Error Code:', error.code);
                console.log(error.code);
                setLoader(false);
              });

    })
    .on('receipt', (receipt) => {
      console.log("approval receipt");
    })
    .on('error', function(error) {
      console.log('Error Code:', error.code);
      console.log(error.code);
      setLoader(false);
    });
  }



  const isdSwap = async (sub,bid  ) => {

    console.log("swap sub",sub,bid);

    let INTERFACE_ID_SWAP = isdTokenSwapId;

    let web3 = window.web3;
    const swapToken = new web3.eth.Contract(
      TokenSwap.abi,
      isdPriceOffer.currency
    );
    console.log("swap test s:",isdPriceOffer.currency, swapToken);

    let supportsTokenSwap = false;

    try {
      supportsTokenSwap = await swapToken.methods
        .supportsInterface(  INTERFACE_ID_SWAP )
        .call();
    } catch (e) {
      console.log("support interface missing",e);
    }

    console.log("swap test r:",supportsTokenSwap);

    if (!supportsTokenSwap) {
      return isdMarketBuy(bid);
    }

    let price = isdPriceOffer.price;
    if (bid>0) {
      price = bid;
    }

    console.log("swap", isdPriceOffer, '#', isdTokenContract._address,  tokenId, price,sub);

    setLoader(true);
    swapToken.methods
     .swap( isdTokenContract._address , tokenId, price, sub )
     .send({
       from: account,
       gasLimit: window.web3.utils.toHex(800000),
       gasPrice: window.web3.utils.toHex(await getGasPrice())
     })
     .on('transactionHash', (hash) => {
       setLoader(false);
       fetchDataFromBlockchain();
     })
     .on('receipt', (receipt) => {
       setLoader(false);
       fetchDataFromBlockchain();
     })
     .on('confirmation', (confirmationNumber, receipt) => {
       setLoader(false);
       fetchDataFromBlockchain();
     })
     .on('error', function(error) {
       console.log('Error Code:', error.code);
       console.log(error.code);
       setLoader(false);
     });


  }



  const isdBuy = async ( sub, bid ) => {

    if (!appStatus) { return; }

    if (bid > 0) {
      console.log("got bid",bid);
    }

    if (isdPriceOffer.currency != '0x0000000000000000000000000000000000000000' ){
      return isdSwap(sub,bid);
    }


    console.log(isdPriceOffer);

    let e = (isdPriceOffer.price / 10**18).toString();
    if (bid > 0) {
      e = (bid / 10**18).toString();
    }
    console.log(e);

    setLoader(true);
    isdTokenContract.methods
      .buy( tokenId )
      .send({
        from: account,
        value: window.web3.utils.toHex(window.web3.utils.toWei( e, 'ether') ),  //HOTFIX DIRTY
        gasLimit: window.web3.utils.toHex(220000),
        gasPrice: window.web3.utils.toHex(await getGasPrice())
      })
      .on('transactionHash', (hash) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('receipt', (receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        setLoader(false);
        fetchDataFromBlockchain();
      })
      .on('error', function(error) {
        console.log('Error Code:', error.code);
        console.log(error.code);
        setLoader(false);
      });
  };



  const isdRestoreMeta = async () => {
    if (!appStatus) {
    } else {
        setLoader(true);

        //let convertToWei = window.web3.utils.toWei(inputPrice, 'Ether');

        //aproving tokens for spending
        isdTokenContract.methods
          .restoreMeta(tokenId)
          .send({
            from: account,
            gasLimit: window.web3.utils.toHex(80000),
            gasPrice: window.web3.utils.toHex(await getGasPrice())
          })
          .on('transactionHash', (hash) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('receipt', (receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('confirmation', (confirmationNumber, receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('error', function(error) {
            setLoader(false);
            console.log('Error Code:', error.code);
            console.log(error.message);
          });
      }
  };


  const isdSetMeta = async () => {
    if (!appStatus) {
    } else {
        setLoader(true);


//        let meta = isdMeta;
        let snftDescriptionLead = "This is physical Art, tokenized by secured S[NFT] Standard. Further Infos on https://snft.institute.\n";

        // hook new properties in
        isdMeta.properties = JSON.parse(inputMeta);

        if (isdMeta.properties.art) {

          // derive ERC721 important attributes from SNFT properties
          //isdMeta.image =
          isdMeta.description  = snftDescriptionLead + isdMeta.properties.art.description;
          isdMeta.external_url = isdMeta.properties.external_link;

          let newName = [
            isdMeta.properties.art.artist,
            "-",
            isdMeta.properties.art.title,
          ];

          if (isdMeta.properties.art.edition) {
            newName.concat(
              "-",
              "No.",
              isdMeta.properties.art.edition.index,
              "of",
              isdMeta.properties.art.edition.total,
              "-",
              isdMeta.properties.art.edition.title
            );
          }

          isdMeta.name  = newName.join(" ");
        }

        /*

          Holy Paradise - No. 3 of 5 - Breaking Borders

           "artist": "Niclas Castello",
           "title": "Holy Paradise",
           "year": "2019",
           "exhibition": "Berlin  Soho House",
           "edition": {
             "title": "Breaking Borders",
             "index": 3,
             "total": 5,
             "special": true
           },
           */

        console.log("new meta", isdMeta);
        // derivate some parts
        //..

        const metaText = JSON.stringify( isdMeta );

        const metaCheckSum = "0x" + md5(metaText);

        // build meta text
        const metaBlob = base64.encode( metaText );

        console.log("meta text", metaText);

        //let convertToWei = window.web3.utils.toWei(inputPrice, 'Ether');

        //aproving tokens for spending
        isdTokenContract.methods
          .setMeta(tokenId, metaBlob, metaCheckSum )
          .send({
            from: account,
            gasLimit: window.web3.utils.toHex(2500000),
            gasPrice: window.web3.utils.toHex(await getGasPrice())
          })
          .on('transactionHash', (hash) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('receipt', (receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('confirmation', (confirmationNumber, receipt) => {
            setLoader(false);
            fetchDataFromBlockchain();
          })
          .on('error', function(error) {
            setLoader(false);
            console.log('Error Code:', error.code);
            console.log(error.message);
          });
    }
  };

 // ///////////////////////


/*
  const tokenListFetch = async () => {
    if (!appStatus) {
    } else {
      setLoader(true);
      isdTokenContract.methods
        .msgSender()
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          console.log("tx",hash);
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('data', (event) => {
          setLoader(false);
          console.log("data",event);
        })
        .on('receipt', (receipt) => {
          console.log("re",receipt);
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          console.log("con",receipt);
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          console.log('Error Code:', error.code);
          console.log(error.code);
          setLoader(false);
        });
    }
  };
*/


const isdDelegateStore = async () => {
  if (!appStatus) {
  } else {
    if (!inputDelegateStore || inputDelegateStore === '' ) {
      setInputDelegateStore('');
    } else {
      setLoader(true);

      //let convertToWei = window.web3.utils.toWei(inputPrice, 'Ether');

      //aproving tokens for spending
      isdTokenContract.methods
        .storeDelegate(tokenId, inputDelegateStore )
        .send({
          from: account,
          gasLimit: window.web3.utils.toHex(80000),
          gasPrice: window.web3.utils.toHex(await getGasPrice())
        })
        .on('transactionHash', (hash) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('receipt', (receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('confirmation', (confirmationNumber, receipt) => {
          setLoader(false);
          fetchDataFromBlockchain();
        })
        .on('error', function(error) {
          setLoader(false);
          console.log('Error Code:', error.code);
          console.log(error.message);
        });

    }
  }
};

const updateAllList = async ( token, item ) => {
  console.log("##########################");
  let _tokenList = [];

  try {
    _tokenList = await token.methods
      .getTokenListByAddress( item,100,1)
      .call();

  } catch (e) {
    console.log("tokenlist problem",e);
  }

  setisdAllList({ items: _tokenList });
}


const inputDigHandler = (received) => {
  setInputDig(received);
};

const isdDig = async (  ) => {

  if (!appStatus) { return; }

  console.log("inputdig", inputDig);

  if (!inputDig || parseInt(inputDig) < 1) { return; }

  // rckPrice is in Ethereum!
  let e = ( inputDig *  isdDigPrice ).toString();
  let g = (800000 * inputDig).toString();

  console.log("price",{
    "price": isdDigPrice,
    "price/10**18": isdDigPrice / (10**18),
    "amount": inputDig,
    "amount_i": parseInt(inputDig),
    "e": e,
    "g": g
  });


  // FIXME:  no tokeuri part !!!!!!!!!!!!!!!
  setLoader(true);
  isdTokenContract.methods
    .dig( parseInt(inputDig).toString() )
    .send({
      from: account,
      value: window.web3.utils.toHex(e),
      gasLimit: window.web3.utils.toHex(g),  //259,888  4x Transfer + 1x Token,   337586 for, 227,500 for 7x transfer + 1x token
      gasPrice: window.web3.utils.toHex(await getGasPrice())
    })
    .on('transactionHash', (hash) => {
      setLoader(false);
      fetchDataFromBlockchain();
    })
    .on('receipt', (receipt) => {
      setLoader(false);
      fetchDataFromBlockchain();
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      setLoader(false);
      fetchDataFromBlockchain();
    })
    .on('error', function(error) {
      console.log('Error Code:', error.code);
      console.log(error.code);
      setLoader(false);
    });
};



  let metamaskURI = "https://metamask.io/download/";
  let braveURI = "https://brave.com/";
  let metamaskDeepURI = "https://metamask.app.link/dapp/" + window.location.href;



  if ( !appStatus ) {

    if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(  navigator.userAgent  ))

      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon} alt="logo" className={classes.icon} />
            <h1> please use mobile Metamask compatible Wallet</h1>
            <p>{account}</p>
            <p>Visit <a href={metamaskURI} target="_blank" rel="noopener noreferrer">Metamask</a> first</p>
            <p></p>
            <h1> open this Link in the Metamask's own Browser</h1>
            <p></p>
            <p>Visit <a href={metamaskDeepURI} rel="noopener noreferrer">DAPP in Metamask</a> then</p>
            <p></p>
            <p>Android Users should enable
            Apps*Standard&nbsp;Apps*Links*MetaMask*Addresses*metamask.app.link before.
            </p>
          </div>
        </div>
      )
   else
      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <img src={icon2} alt="logo" className={classes.icon} />
            <h1> please install Brave-Browser on Desktop or any Metamask compatible Wallet-Plugin </h1>
            <p>{account}</p>
            <p>Visit <a href={braveURI}>Brave</a> first</p>
          </div>
        </div>
      )
  }



  if ( network.id != chainId && chainChangeCounter > 10)

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <img src={icon} alt="logo" className={classes.icon} />
          <h1> wrong Network </h1>
          <p>{network.id}</p>
          <p></p>
          <p>Switch Network to <b>Rinkeby</b> (on Metamask Mobile via Button "...")</p>
        </div>
      </div>
    )


    if (  window.location.href.match(/[\/:]dig/) ||
         tokenId > isdContractInfo.totals
        ){

      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <div className={classes.info}>
              <Dig
                page={page}
                dig={isdDig}
                account={account}
                info={isdContractInfo}
                network={network}
                amount={inputDig}
                contract={isdTokenContract}
                price={isdDigPrice}
                actionHandler={isdDig}
                inputHandler={inputDigHandler}
                userBalance={userBalance}
                gasPrice={gasPrice}
              />
            </div>
          </div>
        </div>
      )
    }


    if (  window.location.href.match(/[\/:]wallet/)  ) {
      return (
        <div>
          <Wallet
            network={network}
            info={isdContractInfo}
            status={isdStatus}
            page={page}
            list={isdTokenList}
            contract={isdTokenContract}
          />
        </div>
      );
    }

    if (  window.location.href.match(/[\/:]all/)  ) {
      return (
        <div>
          <All
            network={network}
            info={isdContractInfo}
            status={isdStatus}
            page={page}
            list={isdAllList}
            contract={isdTokenContract}
          />
        </div>
      );
    }

    if ( tokenId === 0 ) {
      return (
        <div className={classes.Grid}>
          <div className={classes.Child}>
            <Start
              network={network}
              info={isdContractInfo}
              status={isdStatus}
              page={page}
              list={isdTokenList}
              account={account}
              contract={isdTokenContract}
            />
          </div>
        </div>
      );
    }




  return (
    <div className={classes.Grid}>
      {loader ? <div className={classes.curtain}></div> : null}

      {loader ? <div className={classes.curtain}></div> : null}

      <div className={classes.loader}></div>
      <div><img alt='pix' style={{ width: 0 }} src={'/wallet/'+account} /></div>
      <div className={classes.Child}>
        <Navigation changePage={changePage} account={account} metaLoaded={metaLoaded} caps={isdCaps} status={isdStatus} access={isdAccess} contractAddress={contractAddress}>
        <div>
          <Profile
            network={network}
            info={isdContractInfo}
            meta={isdMeta}
            caps={isdCaps}
            status={isdStatus}
            page={page}
            pricelast={isdPriceLast}
            price={isdPriceOffer}
            metaLoaded={metaLoaded}
            metaCheckSum={isdMetaCheckSum}
            contract={isdTokenContract}
            tokenid={tokenId}
          />
        </div>
        <div>
          <Profile721
            network={network}
            info={isdContractInfo}
            meta={isdMeta}
            caps={isdCaps}
            status={isdStatus}
            page={page}
            pricelast={isdPriceLast}
            price={isdPriceOffer}
            metaLoaded={metaLoaded}
            metaCheckSum={isdMetaCheckSum}
            metaURI={isdMetaURI}
            contract={isdTokenContract}
            tokenid={tokenId}
            transferableHandler={isdSetTransferable}
            isTransferable={isdTransferable}
          />
        </div>
        <div>
          <Meta
            account={account}
            page={page}
            meta={isdMeta}
            caps={isdCaps}
            status={isdStatus}
            inputHandler={inputMetaHandler}
            inputValue={inputMeta}
            setValue={isdSetMeta}
            restoreValue={isdRestoreMeta}
          />
        </div>
        <div className={classes.inStore}>
          <InStore
            caps={isdCaps}
            status={isdStatus}
            page={page}
            store={isdStore}
            storeInspect={isdStoreInspect}
            meta={isdMeta}

            setDelegate={isdDelegateStore}
            inputHandler={inputDelegateHandler}
            access={isdAccess}
            account={account}
          />
        </div>
        <div className={classes.outStore}>
          <OutStore
            caps={isdCaps}
            status={isdStatus}
            page={page}
            unStore={isdUnStore}
            unStoreInspect={isdUnStoreInspect}
            meta={isdMeta}
            setDelegate={isdDelegateStore}
            inputHandler={inputDelegateHandler}
            access={isdAccess}
            account={account}
          />
        </div>
        <div className={classes.expert}>
          <Expert
            caps={isdCaps}
            status={isdStatus}
            page={page}
            verify={isdVerify}
          />
        </div>
        <div>
          <Offer
            network={network}
            account={account}
            page={page}
            caps={isdCaps}
            status={isdStatus}
            price={isdPriceOffer}
            setPrice={isdSetPrice}
            delPrice={isdDelPrice}
            inputHandler={inputPriceHandler}
            fees={isdFees}
          />
        </div>
        <div className={classes.buy}>
          <Buy
            caps={isdCaps}
            account={account}
            network={network}
            status={isdStatus}
            page={page}
            buy={isdBuy}
            price={isdPriceOffer}
          />
        </div>
        <div className={classes.info}>
          <Info
            caps={isdCaps}
            account={account}
            network={network}
            gasPrice={gasPrice}
            status={isdStatus}
            meta={isdMeta}
            info={isdContractInfo}
            page={page}
            buy={isdBuy}
            offer={isdPriceOffer}
            lastprice={isdPriceLast}
            contract={isdTokenContract}
            tokenid={tokenId}
            fees={isdFees}
            isTransferable={isdTransferable}
          />
        </div>
         </Navigation>
      </div>
    </div>
     );

}

export default App;
