import React, { Component } from "react";
import PropTypes from "prop-types";


import snftIcon from '../assets/snft.png';
import nftIcon from '../assets/nft.png';
import nfoIcon from '../assets/info.png';
import walletIcon from '../assets/walletWhite.png';



class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { label, onClick } = this.props;

    console.log("tab click:",label);
    if (label == '#' ) {
      let x = window.location.href.split('/').pop();
      let y = x.split(':');
      window.location.href = window.location.href.replace(/\/\?[\/]?.*/,"/?/" + y[0] + ":wallet");
      return;
    }

    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label },
    } = this;

    let className = "tab-list-item";

    if (activeTab === label) {
      className += " tab-list-active";
    }

    return (
      <li className={className} onClick={onClick}>
        {label === "SNFT" ?  <img alt="SNFT" src={snftIcon} height="24px" /> : (
           label === "ERC721" ?  <img alt="NFT" src={nftIcon} height="24px"/> : (
             label === "#" ?  <img alt="Wallet" src={walletIcon} height="24px"/> : (
               label === "*" ?    <img alt="Info" src={nfoIcon} height="24px"/> : label
             )
           )
         ) }
      </li>
    );
  }
}

export default Tab;
