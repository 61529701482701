import React, { useState } from 'react';
import classes from './Profile.module.css';

import { isdCapsCode, isdStatusCode, isdCurrency } from '../isd.js';
import copyicon from '../assets/copymini.png';

import Table from './Table';



const Profile = (props) => {

  const [copiedInfo, setCopiedInfo] = useState(false);
  const [copiedInfo2, setCopiedInfo2] = useState(false);

  const dottedContractAddress = "" + props.contract._address.substring(0,5) + "..." + props.contract._address.substr(-4);
  const contractAddress = props.contract._address;

  let uriAssetDeepLink = [ "https://metamask.app.link/dapp", window.location.href.replace("https://","") ].join("/");

  const copyAddress = (event) => {
     navigator.clipboard.writeText(contractAddress);

     setCopiedInfo(true);

     setTimeout(() => {
       setCopiedInfo(false);
    }, 3000);

  };

  const copyAddress2 = (event) => {
     navigator.clipboard.writeText( uriAssetDeepLink );

     setCopiedInfo2(true);

     setTimeout(() => {
       setCopiedInfo2(false);
    }, 3000);

  };

/*
  <div style={{position: 'absolute', bottom: '-16px', left: '10px'}}>
      <p style={{
        "font-size": "30px",
        "font-weight": "800",
        "color": "transparent",
        "text-align": "left",
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "black"
      }}>{props.price > 0 ? props.price + " ETH" : "UNPRICED" }</p>
  </div>
  <div style={{position: 'absolute', top: '-36px', left: '10px'}}>
      <p style={{
        "font-size": "30px",
        "font-weight": "800",
        "color": "transparent",
        "text-align": "left",
        "-webkit-text-stroke-width": "1px",
        "-webkit-text-stroke-color": "black"
      }}>{props.caps[isdCapsCode.own] ? "YOUR PROPERTY" : ""}</p>
  </div>
*/

  const price = ( p , preTxt, noTxt ) => {
    if ( ! isdCurrency[ p.currency ]) {
      return "X " + p.price;
    }
    return ( p.price > 0 ? preTxt + p.price/(10**isdCurrency[ p.currency ].decimals) + " " + isdCurrency[ p.currency ].name :  noTxt );
  }


/*
  <div className={classes.box} >
      <div className={classes.frostedGlassEffect}>
        <div className={classes.preview}>
             <img width="100%" src={props.meta.image} alt="NFTX Preview" />
             <div className={classes.previewPrice}>
               { price(props.pricelast, "", " ","")}
               { props.status.flags[ isdStatusCode.forSale ] ? price(props.price, "FOR SALE FOR ", "","")  : ""}
             </div>
        </div>
      </div>

      <div className={classes.box.container} style={{position: 'relative'}}>
*/

  return (
    <div className={classes.profile}>
      <div className={classes.box} >
        <div className={classes.frostedGlassEffect}>
          <div className={classes.preview}>
            {
              /*
               (props.tokenid === 7) ?
                 <iframe title="demo" style={{ border: "0px" }} class="image" src="https://niclascastellofoundation.org/view/env22.html"></iframe>
               :
               */
                 <img width="100%" src={props.meta.image} alt="SQARE Preview" class="image"/>

            }
          </div>
        </div>
        <div>
          { price(props.pricelast, "", "PRICELESS") }
          {  props.status.flags[ isdStatusCode.forSale ] ? price(props.price, (props.price.till <= Date.now()/1000 ? " - FOR SALE FOR " : " - AUCTIONED FOR "), "")  : ""}
        </div>
        <div>
        {  props.status.codes[ isdStatusCode.stage ] < 2 ? " NOT YET TRADABLE " : ( props.status.codes[ isdStatusCode.stage ] > 2 ? " NOT TRADABLE ANYMORE ":"") }
        </div>

        <div>
          <p>
          Token &nbsp;
          <img alt="Copy" width="15px" src={copyicon} onClick={copyAddress2}/>
          { copiedInfo2 ?
           "Copied"
           : null
          }
          </p>

          <Table data={[

            ["title",      props.meta.name ],
            ["collection", props.info.name ],
              props.meta.external_link ?
               [ "external", <a href={props.meta.external_link} rel="noopener noreferrer" target="_blank">Website</a> ]
                :
               [ null ],
            ["owner",      props.caps[isdCapsCode.own] ? "You" : "Someone else" ],
            ["meta",       props.status.flags[isdStatusCode.onChain] ? "on chain" : "off chain" ],
            ["checksum",   props.metaCheckSum.token +
               props.metaCheckSum.token == props.metaCheckSum.calc ? "valid" : (
                  props.metaCheckSum.token == "0x0" ? "unset" : "invalid"
                )
              ]
          ]}/>

        </div>
        <div>
          <p>Address & ID</p>
          <ul>
            <li>contract: <b>{props.contract._address}</b>&nbsp;
              <img alt="Copy" width="15px" src={copyicon} onClick={copyAddress}/>
              { copiedInfo ?
               "Copied"
               : null
              }
             </li>
            <li>token id: <b>{props.tokenid}</b> </li>
            <li><br/></li>
          </ul>
          </div>
          <div>
          <p>Storage Location</p>
          <Table data={[
            ["stored at", <a href={props.meta.properties.storage.web}  rel="noopener noreferrer" target="_blank">{props.meta.properties.storage.title}</a> ],
            ["portclass", props.meta.properties.storage.type ]
          ]}/>
        </div>
      </div>
      <div><p>
      <br/>
      <br/>
      </p></div>
    </div>
  );
};

export default Profile;
