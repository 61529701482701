import React, { useState } from 'react';
import classes from './Start.module.css';
import SNFT from '../assets/snft.png';



const Start = (props) => {

  const etherScan = ( networkId ) => {
    if (networkId === 4) {
      return "https://rinkeby.etherscan.io/address/";
    }
    if (networkId === 5) {
      return "https://goerli.etherscan.io/address/";
    }
    if (networkId === 1) {
      return "https://etherscan.io/address/";
    }
  }

  if (! props.contract) {

    return (
      <div className={classes.Grid}>
        <div className={classes.Child}>
          <h1> connecting ... </h1>
        </div>
      </div>
    );

  }

  return (
    <div className={classes.Info}>

      <img alt="Secure Non Fungible Token" src={SNFT} width="200px"/>
      <br/>

      {  props.list.length > 0 ?

        <a href={"/?/" + props.contract._address + ":wallet"}>
        <button className={classes.StartButton}>
        Gallery
        </button>
        </a>

        :

        <button className={classes.StartButton} disabled>
        NO SQARE in your wallet
        </button>
      }
      <br/>
      <br/>
      <a href={"/?/" + props.contract._address + ":dig"}>
      <button className={classes.StartButton}>
      Dig
      </button>
      </a>

      <br/>
      <br/>

      <div className={classes.box}>
        <p> wallet: <b><a href={props.network.id ? etherScan(props.network.id) + props.account : ""} target="scan"  rel="noopener noreferrer" >{props.account}</a></b> ({"<-Click"})</p>
        <p> contract: <b><a href={props.network.id ? etherScan(props.network.id) + props.contract._address : ""} target="scan"  rel="noopener noreferrer" >{props.contract._address}</a></b> ({"<-Click"})</p>
        <p> network: <b>{props.network.name}</b> (id: <b>{props.network.id}</b>)</p>
      </div>

    </div>
  );
};

export default Start;
