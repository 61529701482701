import React, { useState } from 'react';
import classes from './OutStore.module.css';
import stakeIcon from '../assets/stake.png';


import { isdCapsCode, isdStatusCode, isdAccessManagerCode } from '../isd';


const OutStore = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
    props.inputHandler(event.target.value);
  };




  return (
    <div className={classes.outStore}>
      <p>FOR REGISTERED PERSONNEL ONLY</p>


      <button
      disabled={!props.caps[isdCapsCode.inspect] || !props.status.flags[isdStatusCode.unLocked] }
      onClick={props.unStoreInspect}>
      Unstore
      </button>
      &nbsp; &nbsp;
      <button
      disabled={
           !props.caps[isdCapsCode.store]
         || props.status.flags[isdStatusCode.unLocked]
         || props.status.flags[isdStatusCode.unStored]
         || !props.status.flags[isdStatusCode.unStoredInspect]
        }
      onClick={props.unStore}>
      Confirmed
      </button>


      <div className={classes.outStore}>
        <p>Warehouse/Port</p>
        <ul>
        <li>stored at: <b><a href={props.meta.properties.storage.web}  rel="noopener noreferrer" target="_blank">{props.meta.properties.storage.title}</a></b> ({"<-Click"})</li>
        <li>portclass: <b>{props.meta.properties.storage.type}</b></li>
        <li>authorized Take-Out: <b>{ props.access.manager[isdAccessManagerCode.store] === props.account ? "You" : props.access.manager[isdAccessManagerCode.store] }</b></li>
        </ul>
      </div>


      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="char"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.own]  }
        onClick={() => {
          props.setDelegate();
          setInputValue('');
        }}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        <p>Delegate</p>
      </button>

      <div className={classes.outStore}>
        <p>Status</p>
        <ul>
        <li> unlocked: {props.status.dates[isdStatusCode.unLocked]}</li>
        <li> stored: {props.status.dates[isdStatusCode.stored]}</li>
        <li> storedInspect: {props.status.dates[isdStatusCode.storedInspect]}</li>
        <li> unStoredInspect: {props.status.dates[isdStatusCode.unStoredInspect]}</li>
        <li> unStored: {props.status.dates[isdStatusCode.unStored]}</li>
        </ul>
      </div>
    </div>
  );
};

export default OutStore;
