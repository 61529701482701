import React, { useState } from 'react';
import classes from './InStore.module.css';
import stakeIcon from '../assets/stake.png';


import { isdCapsCode, isdStatusCode, isdAccessManagerCode } from '../isd';

console.log("==========");
console.log( isdCapsCode );
console.log( isdStatusCode );
console.log("==========");




const InStore = (props) => {

  const [inputValue, setInputValue] = useState('');

  const inputChangeHandler = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
    props.inputHandler(event.target.value);
  };


  return (
    <div className={classes.inStore}>
      <p>FOR REGISTERED PERSONNEL ONLY</p>

      <button
      disabled={!props.caps[isdCapsCode.store] || props.status.flags[isdStatusCode.stored] || props.status.flags[isdStatusCode.unLocked] || !props.meta.properties.notes.storage }
      onClick={props.store}>
      { props.meta.properties.notes.storage ? "Store" : "Costs missing" }
      </button>
      &nbsp; &nbsp;
      <button
      disabled={!props.caps[isdCapsCode.inspect] || props.status.flags[isdStatusCode.unLocked] || !props.status.flags[isdStatusCode.stored] }
      onClick={props.storeInspect}>
      { props.status.flags[isdStatusCode.stored] ? "Confirm" : "Store first!" }
      </button>

      <div className={classes.inStore}>
      { props.meta.properties.notes.storage ?
        <div>Storage Fee: <b>{(props.meta.properties.notes.storage) + " ETH"}</b></div>
        :
        <div>Ask your SQARE-Tokenizer for Storaging</div>
       }
      </div>
      <br/>

      <div className={classes.inStore}>
        <p>Warehouse/Port</p>
        <ul>
        <li>stored at: <b><a href={props.meta.properties.storage.web}  rel="noopener noreferrer" target="_blank">{props.meta.properties.storage.title}</a></b> ({"<-Click"})</li>
        <li>portclass: <b>{props.meta.properties.storage.type}</b></li>
        <li>authorized Bring-In: <b>{ props.access.manager[isdAccessManagerCode.store] === props.account ? "You" : props.access.manager[isdAccessManagerCode.store] }</b></li>
        </ul>
      </div>


      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          type="char"
          onChange={inputChangeHandler}
          value={inputValue}
        ></input>
      </div>

      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.own]  }
        onClick={() => {
          props.setDelegate();
          setInputValue('');
        }}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        <p>Delegate</p>
      </button>


      <div className={classes.inStore}>
        <p>Status</p>
        <ul>
          <li> unlocked: {props.status.dates[0]}</li>
          <li> stored: {props.status.dates[1]}</li>
          <li> storedInspect: {props.status.dates[2]}</li>
        </ul>
      </div>
    </div>
  );
};

export default InStore;
