import React from 'react';
import classes from './Profile.module.css';

import etherIcon from '../assets/ether.png';
import snftIcon from '../assets/snft.png';
import dappIcon from '../assets/dapp.png';

import metaMaskIcon from '../assets/icon.png';

import { isdCurrency } from '../isd.js';


const Profile = (props) => {

    const etherScan = ( networkId, type ) => {
      if (networkId === 5) {
        return "https://goerli.etherscan.io/address/";
      }
      if (networkId === 11155111) {
        return "https://sepolia.etherscan.io/address/";
      }
      if (networkId === 4) {
        return "https://rinkeby.etherscan.io/"+type+"/";
      }
      if (networkId === 1) {
        return "https://etherscan.io/"+type+"/";
      }
    }

    const nanoEth = (p) =>  {      return Math.round(p * 10**(9-18));    }
    const mikroEth = (p) => {      return Math.round(p * 10**(6-18));    }
    const milliEth = (p) => {      return Math.round(p * 10**(3-18));    }
    const eth = (p) =>      {      return Math.round(p * 10**(0-18),3);    }

    let on  = "ON";
    let off = "OFF";

    const price = ( p , preTxt, noTxt ) => {
      if ( ! isdCurrency[ p.currency ]) {
        return "X " + p.price;
      }
      return ( p.price > 0 ? preTxt + p.price/(10**isdCurrency[ p.currency ].decimals) + " " + isdCurrency[ p.currency ].name :  noTxt );
    }

    const fee = ( f ) => {
      return ( f.value * 100 / props.fees.denominator ) +"%";
    }

  return (
    <div className={classes.profile}>
      <div className={classes.network} width="200">
        <img alt="Ethereum" src={etherIcon} width="50px"/>
        <p>Ethereum Network</p>
        <ul>
        <li> network: <b>{props.network.name}</b> (id: <b>{props.network.id}</b>)</li>
        <li> gasprice: <b>{nanoEth(props.gasPrice)}nETH</b></li>
        <li> web3: <b>{window.location.href}</b></li>
        </ul>
        <p><br/></p>
        {
          window.ethereum.isMetaMask ?
            <img alt="MetaMask" src={metaMaskIcon} width="80px"/>
            :
            ""
        }
        <p>Wallet</p>
        <ul>
        <li> address: <b><a href={etherScan(props.network.id,"address") + props.account} target="scan"  rel="noopener noreferrer" >{props.account}</a></b> ({"<-Click"})</li>
        <li> name: <b>
          { window.ethereum.isMetaMask ? "Metamask" : (
             window.ethereum.isCoinbaseWallet ? "Coinbase Wallet" : (
               window.ethereum.isTrustWallet ? "Trust Wallet" : (
                 "Unknown Wallet"
               )
            )
          )
          }
        </b></li>
        </ul>
        <p><br/></p>
        <img alt="SNFT" src={snftIcon} width="80px"/>
        <p>Contract</p>
        <ul>
        <li> name:     <b>{props.info.name}</b></li>
        <li> symbol:     <b>{props.info.symbol}</b></li>
        <li> address: <b><a href={etherScan(props.network.id,"token") + props.contract._address} target="scan"  rel="noopener noreferrer" >{props.contract._address}</a></b> ({"<-Click"})</li>
        <li> id of totals: <b>{props.tokenid}</b> of <b>{props.info.totals}</b></li>
        <li> deployedBy: <b>{props.info.deployedBy}</b></li>
        <li> specVersion: <b>{props.info.specVersion}</b></li>
        <li> decimals:  <b>{props.info.decimals}</b></li>
        </ul>
        <p><br/></p>
        <img alt="Info" src={props.info.image} width="100px"/>
        <p>Status</p>
        <ul>
        <li> unLocked: <b>{props.status.dates[0]}</b></li>
        <li> inStored: <b>{props.status.dates[1]}</b></li>
        <li> inInspected: <b>{props.status.dates[2]}</b></li>
        <li> outInspected: <b>{props.status.dates[3]}</b></li>
        <li> outStored: <b>{props.status.dates[4]}</b></li>
        <li> verified: <b>{props.status.dates[5]}</b></li>
        <li> forSale: <b>{props.status.dates[6]}</b></li>
        <li> transferable: <b>{props.isTransferable ? "yes":"no"}</b></li>
        </ul>
        <p>Caps</p>
        <ul>
        <li> status: <b>{props.caps[0] ?  on : off }</b></li>
        <li> meta:   <b>{props.caps[1] ?  on : off}</b></li>
        <li> store:  <b>{props.caps[2] ?  on : off}</b></li>
        <li> inspect:<b>{props.caps[3] ?  on : off}</b></li>
        <li> verify: <b>{props.caps[4] ?  on : off}</b></li>
        <li> offer:  <b>{props.caps[5] ?  on : off}</b></li>
        <li> buy:    <b>{props.caps[6] ?  on : off}</b></li>
        <li> burn:   <b>{props.caps[7] ?  on : off}</b></li>
        <li> own:    <b>{props.caps[8] ?  on : off}</b></li>
        </ul>
        <p>Prices & Fees (Disagio)</p>
        <ul>
          <li> Asset: <b>{ price(props.lastprice, "",  "n/a") }</b></li>
          <li> Offer: <b>{ price(props.offer, "",  "n/a") }</b></li>
          <li> Auction: <b>{ props.offer.forSale <= Date.now()/1000 && props.offer.endSale >= Date.now()/1000 ? "running" :  "n/a" }</b></li>
          <li> Market: <b>{ fee(  props.fees.market) }</b></li>
          <li> FirstSale: <b>{ fee(props.fees.firstSale) }</b></li>
          <li> Royalties: <b>{ fee(props.fees.royalties[0]) }</b></li>
        </ul>
        <p><br/></p>
        <img alt="DApp" src={dappIcon} width="100px"/>
        <p>Principle of Decentralized App</p>
        <div><p>
          This decentralized Application (<b>DApp</b>) is provided <u>free of charge</u> by the <b>Interactive Software Development LLC</b>.
          It does not interact with third services or stores your data.
          <br/>
          <br/>
          This <b>DApp</b> communicates with your <b>Wallet</b> only. Your <b>Wallet</b> communicates with the <b>Ethereum Network</b>.
          You, the user, has full control of any operation of this <b>DApp</b>. You allow read access on your <b>Wallet</b> on start.
          Every transaction that is made by this <b>DApp</b> on your <b>Wallet</b>, needs explicit confirmation by You.
          <br/>
          <br/>
          Time, Place, Fees and Costs of transactions may vary depending on runtime parameters of the <b>Contracts</b>, the <b>Ethereum Network</b>,
           your <b>Wallet</b> and any <b>Ethereum Provider</b> between <b>Wallet</b> and the <b>Ethereum Network</b>.
          <br/>
          </p>
        </div>
        <p>Disclaimer</p>
        <div>
        <p>
          THIS <b>DAPP</b>, CONTENT AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS
          SERVICE PROVIDERS DO NOT WARRANT OR GUARANTEE THE ACCURACY, COMPLETENESS, OR ADEQUACY OF THIS <b>DAPP</b> OR ANY CONTENT OR
          SERVICES AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED IN THE CONTENT OR OTHER DATA, INFORMATION,
           OR USER CONTENT THAT MAY BE PROVIDED THROUGH THIS <b>DAPP</b>. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS
           EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THIS <b>DAPP</b>, CONTENT, SERVICES
           AND OTHER SUBJECT MATTER OF THIS AGREEMENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
            ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION
            OR ADVICE GIVEN BY INTERACTIVE SOFTWARE DEVELOPMENT LLC, ITS EMPLOYEES, OR SERVICE PROVIDERS WILL INCREASE THE SCOPE OF,
             OR CREATE ANY NEW WARRANTIES. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS MAKE NO WARRANTY THAT
              (a) THIS <b>DAPP</b> WILL MEET YOUR REQUIREMENTS,
              (b) THIS <b>DAPP</b> WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
              (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS <b>DAPP</b> WILL BE ACCURATE OR RELIABLE,
              (d) THE QUALITY OF ANY CONTENT OR SERVICES PURCHASED OR OBTAINED BY YOU THROUGH THIS <b>DAPP</b> WILL MEET YOUR EXPECTATIONS, OR
              (e) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED.
            ANY CONTENT OR SERVICES DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS <b>DAPP</b> IS DONE AT YOUR OWN DISCRETION AND RISK
            AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </p>
          <p><br/></p>
          <p><br/></p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
