import React from 'react';
import classes from './Expert.module.css';

import { isdCapsCode, isdStatusCode } from '../isd.js';


const Expert = (props) => {

  return (
    <div className={classes.expert}>
      <p>FOR REGISTERED PERSONNEL ONLY</p>

      <button
      disabled={!props.caps[isdCapsCode.verify] || props.status.flags[isdStatusCode.unLocked]  }
      onClick={props.verify}>
      Verify
      </button>


      <div className={classes.expert}>
        <p>Status</p>
        <ul>
          <li> unlocked: {props.status.dates[isdStatusCode.unLocked]}</li>
          <li> inStoredInspected: {props.status.dates[isdStatusCode.stored]}</li>
          <li> verified: {props.status.dates[isdStatusCode.verified]}</li>
        </ul>
      </div>
    </div>
  );
};

export default Expert;
