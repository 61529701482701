import React from "react";

import "./Table.css";


const Table = (props) => {

    console.log("x",props)

    return (
       <div className="tbl">
         <table >
          {props.head ?
           <thead >
             <tr>
              {props.heading.map((head) => (
                   <th>{head}</th>
                 ))
               }
             </tr>
           </thead>
           :
           ""
          }
          { props.data ? 
           <tbody >
             {props.data.map((row) => (
               row.length > 1 ?
                 <tr>
                   {row.map((val,i) => (
                     <td >
                       {val}
                     </td>
                   ))}
                 </tr>
                 :
                 ""
             ))}
           </tbody>
           :
           ""
          }
         </table>
       </div>
     );
}

export default Table;
