import React, { useState } from 'react';

import classes from './Buy.module.css';

import { isdCapsCode, isdStatusCode, isdCurrency, isdNullAddress, isdTokenSwapId } from '../isd.js';

import ERC20 from '../../src/abis/ERC20.json';
import TokenSwap from '../../src/abis/TokenSwap.json';



const Buy = (props) => {

  const [userBalance, setUserBalance] = useState("...");
  const [supportsTokenSwap, setSupportsTokenSwap] = useState(false);
  const [maxBalances, setMaxBalances] = useState(false);

  const [inputBid, setInputBid] = useState(0);


  const goRamp = () => {

    //  let dottedFriendAddress = "" + props.friendAddress.substring(0,6) + "..." + props.friendAddress.substr(-4);
      let uriParams =
          {
            userAddress: props.account,
            /*
            swapAsset: 'ETH_ETH',
            swapAmount: 1000 * 10**18,
            */
            defaultAsset: props.priceCurrency === isdNullAddress ? 'ETH' : "USDT",
   
            fiatCurrency: 'usd',
            fiatValue: '1000',
   
            hostLogoUrl: encodeURI('https://sqares.com/assets/images/sqares_orange.png'),
            hostAppName: 'SQUARES',
            /*
            */
   
   //         enabledPaymentMethods: ['credit_debit_card', 'apple_pay', 'google_pay', 'sepa_bank_transfer'],
            /*
            externalCustomerId: '[your_user_id]',
            externalTransactionId: '[your_tx_id]',
            */
            /*
            colorCode: '#FD5524', //FF875B',
            language: 'en',
            */
          };
   
      //let url = "https://buy.ramp.network/?";
      let url = 'https://ramp.network/buy/?';
   
   
      let uriParamList = [];
      for (const key in uriParams) {
        if (key == "enabledPaymentMethods") {
          uriParamList.push( `${key}=${uriParams[key].join(",")}` );
   
        } else {
          uriParamList.push( `${key}=${uriParams[key]}` );
        }
      }
      url += uriParamList.join("&");
   
   /*
       // HOT FIX
       if (props.priceCurrency === xNullAddress ) {
         url = 'https://ramp.network/buy/?defaultAsset=ETH';
       } else {
         url = 'https://ramp.network/buy/?defaultAsset=USDT';
       }
   */
   
      console.log("ramp",url);
   
      window.open(url, '_blank', 'noopener,noreferrer');
   
   }
   


   const goFCCT = () => {

      let uriParams =
          {
            userAddress: props.account,
            defaultAsset: 'USDZ',
   
            fiatCurrency: 'usd',
            fiatValue: '10000',
          };
   
      let url = 'https://www.fcct.ae/kyc.html?';
   
   
      let uriParamList = [];
      for (const key in uriParams) {
          uriParamList.push( `${key}=${uriParams[key]}` );
      }
      url += uriParamList.join("&");
   
   
      console.log("fcct",url);
   
      window.open(url, '_blank', 'noopener,noreferrer');
   
   }


   const unix2stamp = (s) => {
     if (parseInt(s) === 0) {
       return("n/a");
     } else {
       let dt = new Date(s * 1000);
       return(
             dt.getDate()+
         "/"+(dt.getMonth()+1)+
         "/"+dt.getFullYear()+
         " "+dt.getHours()+
         ":"+dt.getMinutes()+
         ":"+dt.getSeconds()
       );
     }
   }

   const calcLeft = (l) => {

      if (l>24*3600) {
        return Math.ceil(l/(24*3600)) + "d";
      }
      if (l>3600) {
        return Math.ceil(l/(3600)) + "h";
      }
      if (l>60) {
        return Math.ceil(l/(60)) + "m";
      }
      return Math.ceil(l) + "s";
   }

   const handleChangeBid = (event) => {
     console.log("hcb",event);
     event.preventDefault();

//     setInputPrice(event.target.value);

     setInputBid(event.target.value  * 10**isdCurrency[ props.price.currency ].decimals );
   }

  const checkBalance = async () => {

    let web3 = window.web3;

    if (props.price.currency == isdNullAddress) {
      const result = await web3.eth.getBalance(props.account);
      console.log( web3.utils.fromWei(result,"ether") );
      setUserBalance(web3.utils.fromWei(result,"ether"));
    } else {

console.log(props);

      const coinToken = new web3.eth.Contract(
        ERC20.abi,
        props.price.currency
      );

      let b = await coinToken.methods
        .balanceOf(props.account)
        .call();

      /*
      let s = await coinToken.methods
        .symbol()
        .call();


      let d = await coinToken.methods
        .decimals()
        .call();
      */
      console.log( "balance",b );
      setUserBalance( b );


      let INTERFACE_ID_SWAP = isdTokenSwapId; //"0x98f6200e"; //"0xa3fb3bc7"; //0x9eceb05f";

      const swapToken = new web3.eth.Contract(
        TokenSwap.abi,
        props.price.currency
      );

      let _supportsTokenSwap = false;
      try {
        _supportsTokenSwap = await swapToken.methods
          .supportsInterface(  INTERFACE_ID_SWAP )
          .call();
      } catch (e) {
        console.log("support interface missing",e);
      }

      console.log("swap test b:",_supportsTokenSwap);
      setSupportsTokenSwap(_supportsTokenSwap);


      if (_supportsTokenSwap) {
        let _maxBalances = 0;
        try {
          _maxBalances = await swapToken.methods
          .availableBalances()
          .call();
        } catch (e) {
          console.log("tokenswap balances interface missing",e);
        }
        console.log("swap test c:",_maxBalances);
        setMaxBalances(_maxBalances);
      }
    }
  }

  checkBalance();

/*
  if (inputBid == 0)
    setInputBid(props.price.price*1  + 1*10**isdCurrency[ props.price.currency ].decimals);
*/

  return (
    <div className={classes.buy}>

      <div className={classes.auction}>

        {props.price.till > 0 ? (
            props.price.from <= Date.now()/1000 ?
            (
              props.price.till < Date.now()/1000 ?
                <p>Auction ended { calcLeft( Date.now()/1000 - props.price.till)} ago</p>
                :
                <p>Auction is running, { calcLeft( props.price.till  - Date.now()/1000 )} left</p>
            )
            :
            <p>Auction starts in { calcLeft( props.price.from  - Date.now()/1000)} </p>
          )
          :
          ""
        }
      </div>
      <div className={classes.bid}>
        {props.price.till > 0 ? "Highst Bid" : "Price"}
        :&nbsp;
        {props.price.price/(10**isdCurrency[ props.price.currency ].decimals) + " "+  isdCurrency[ props.price.currency ].name }
      </div>
      <div>
        <br/>
        {props.caps[isdCapsCode.own] ? "YOUR SQARE" : "NOT YOUR SQARE"}
      </div>

      <p>Your Balance: { Math.floor(userBalance / (10**isdCurrency[ props.price.currency ].decimals)  *1000)/1000   + " "+  isdCurrency[ props.price.currency ].name }
        &nbsp;&nbsp;
        {  isdCurrency[ props.price.currency ].name == "USDT" ? 
              <button onClick={goRamp}
                className={classes.rampButton}
                >
                  <p className='classes.rampButton'>Charge</p>
                </button>
            :
            ""
        }      
        {  isdCurrency[ props.price.currency ].name == "USDZ" ? 
              <button onClick={goFCCT}
                className={classes.fcctButton}
                >
                  <p className='classes.fcctButton'>Charge</p>
                </button>
            :
            ""
        }      
      </p>


      { props.price.till > 0 ?
          <div className={classes.inputDiv}>
            <input
              className={classes.input}
              onChange={handleChangeBid}
              value={ parseInt(inputBid)  / 10**isdCurrency[ props.price.currency ].decimals }
            ></input>
          </div>
         :
         ""
      }
      <br/>
      <br/>
      <button
      className={classes.action}
      disabled={
             !props.caps[isdCapsCode.buy]
          ||  (props.price.till > 0 && (props.price.from > Date.now()/1000  || props.price.till < Date.now()/1000 ))
          ||  !props.status.flags[isdStatusCode.forSale]
          ||  !props.status.flags[isdStatusCode.unLocked]
        }
      onClick={() => {
        props.buy(0,inputBid)
      }}>

      { props.price.till > 0 ? "Bid" : "Buy" }

      </button>
      &nbsp;
      { maxBalances == 2 ?
        <button
        className={classes.action}
        disabled={
               !props.caps[isdCapsCode.buy]
           ||  (props.price.till > 0 && (props.price.from > Date.now()/1000  || props.price.till < Date.now()/1000 ))
           ||  !props.status.flags[isdStatusCode.forSale]
           ||  !props.status.flags[isdStatusCode.unLocked]
        }
        onClick={() => {
          props.buy(1,inputBid)
        }}>
        { props.price.till > 0 ? "Bid" : "Buy" }
        &nbsp;
        from Vault
        </button>
        :
        ""
      }

      { maxBalances == 2 ?
        <div><br/>Balance of your vault is not been shown here or checked before the trade action.</div>
      :""
      }

      <div className={classes.buy}>
        <p>Status</p>
        <ul>
          <li> unlocked: {props.status.dates[isdStatusCode.unLocked]}</li>
          <li> forSale:  {props.status.dates[isdStatusCode.forSale]}</li>
          <li> starts: <b>{ props.price.from >0 ? unix2stamp(props.price.from) : 'not for sale' }</b></li>
          <li> finishs: <b>{ props.price.till >0 ? unix2stamp(props.price.till) : 'not in auction' }</b></li>
          <li> currency: {props.price.currency === isdNullAddress ? "Ethereum" : props.price.currency}</li>
          { supportsTokenSwap ?
            <li> tokenSwap: secure exchange supported by currency </li>
            :
            ""
          }
          { maxBalances ?
            <li> balancesCount: {maxBalances}</li>
            :
            ""
          }
        </ul>
      </div>
    </div>
  );
};

export default Buy;
