
import React, { useState } from 'react';
import classes from './Profile.module.css';

import { isdStageText } from '../isd.js';


const All = (props) => {


  return (
    <div className={classes.profile}>
      <div  >
      <ul>
      {
          props.list.items.map(function(item,i){
            console.log(item);

            if (item.id == 0){
              return;
            }

            let imageURL = props.info.baseURI + item.URI + "/preview.png";
            let linkURL = "/?/" + props.contract._address + ":" + "0x"+ parseInt(item.id).toString(16);

            if (item.URI.length <= 1) {
              imageURL = props.info.baseURI + "/empty.png";
            }

            console.log(imageURL);
/*
            return (
              <li style={{ display: "inline-block", "list-style": "none"}}>
              <a href={linkURL}>
              <img alt="Preview" style={{ margin: "10px", width: "160px"}}  src={imageURL} />
              </a>
              </li>
          )
          */
          return (
            <li key={i} className={classes.gridItem} style={{ display: "inline-block", "list-style": "none"}}>
              <div className={classes.frostedGlassEffect}>
                <div className={classes.preview}>
                  <a href={linkURL}>
                    <img alt="Preview" className={classes.previewItem}  src={imageURL} />
                    <div className={classes.previewInfo}>
                      #{item.id}  &nbsp; &nbsp;
                      { isdStageText[item.stage] }
                    </div>
                  </a>
                </div>
              </div>
            </li>
        )

          })
      }
     </ul>
     </div>
    <div><p>
    <br/>
    <br/>
    </p></div>
  </div>

  );
};

export default All;
