import React from 'react';
import classes from './Meta.module.css';
import stakeIcon from '../assets/stake.png';

import { isdCapsCode, isdStatusCode, isdStageCode } from '../isd';



// Import Brace and the AceEditor Component
//import AceEditor from 'react-ace';




const Meta = (props) => {


  const inputChangeHandler = (event) => {

    console.log("change:" , event.target);

    props.inputHandler(event.target.value);
  };


/*
  <AceEditor
    mode="json"
    theme="tomorrow_night_blue'"
    onChange={inputChangeHandler}
    name="miez"
    editorProps={{ $blockScrolling: true, $useWrapMode: "b" }}
    value={props.inputValue}
    height="400px"
    width="300px"
  />
*/

  return (
    <div className={classes.Meta}>
      <div className={classes.inputDiv}>
        <textarea style={{width: "90%", height: "300px" }}
           value={props.inputValue}
           onChangeText={inputChangeHandler}
           />
      </div>
      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.meta] ||  props.status.flags[isdStatusCode.unLocked] ||  props.status.codes[isdStatusCode.stage] > isdStageCode.init  }
        onClick={props.setValue}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        <p>Save</p>
      </button>
      &nbsp;
      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.meta] ||  props.status.flags[isdStatusCode.unLocked] }
        onClick={props.restoreValue}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        <p>Restore</p>
      </button>
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Meta;
