import React, { useState } from 'react';
import Switch from "react-switch";

import classes from './Profile.module.css';

import { isdCapsCode, isdStatusCode } from '../isd.js';


import Table from './Table';


import copyicon from '../assets/copymini.png';




const Profile721 = (props) => {

  const [copiedInfo, setCopiedInfo] = useState(false);

  const dottedContractAddress = "" + props.contract._address.substring(0,5) + "..." + props.contract._address.substr(-4);
  const contractAddress = props.contract._address;

  const copyAddress = (event) => {
     navigator.clipboard.writeText(contractAddress);

     setCopiedInfo(true);

     setTimeout(() => {
       setCopiedInfo(false);
    }, 3000);

  };


  const isIOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }


  const addToWallet = ( ) => {

  let web3 = window.web3;

  web3.eth.currentProvider.sendAsync({
    method:'wallet_watchAsset',
    params:{
      'type':'ERC721',
      'options':{
        'address': props.contract._address,
        'symbol': props.info.symbol,
        'decimals':props.info.decimals,
        'id': props.tokenid
      },
   },
   id:Math.round(Math.random()*100000)
  }, function(err,data){
    if(!err) {
      if(data.result){
         console.log('Token added');
       }else{
         console.log(data);
         console.log('Some error');
       }
     } else {
       console.log(err.message);
     }
   });
 /*
 }catch(e){console.log(e);}});return false;}else{try{web3obj.eth.currentProvider.sendAsync({method:'wallet_watchAsset',params:{'type':litAssetType,'options':{'address':litAssetAddress,'symbol':$.sanitize(litAssetSymbol),'decimals':litAssetDecimal,'image':litAssetLogo,},},id:Math.round(Math.random()*100000)},function(err,data){if(!err){if(data.result){console.log('Token added');}else{console.log(data);console.log('Some error');}}else{console.log(err.message);}});}catch(e){console.log(e);}}});}
*/

}


/*
  const closeBox = (event) => {
     setCopiedInfo(false);
  };
*/
  console.log("dCA",dottedContractAddress,props.contract._address);

  let arLink = 'intent://arvr.google.com/scene-viewer/1.0?file=' + props.metaURI + '/3D.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://snft.ae;end;';

  if (isIOS()) {
    arLink = props.metaURI + '/3D.usdz';
  }

  let webLink = props.metaURI.replace(/paragonart.org/,'snft.ae/viewer.html?');


  return (
    <div className={classes.profile}>
       {props.caps[isdCapsCode.own] ?
        <div>
            <Switch
              className="react-switch"
              onChange={props.transferableHandler}
              checked={props.isTransferable}
              onColor="#779DC9"
              offColor="#072A46"
            />
            <span style={{ position: "relative", top: "-0.5em" }}>ERC721 transferable</span>
        </div>
        :
        ""
        }
        <div className={classes.box} >
        <div><br/></div>
          <div className={classes.frostedGlassEffect}>
            <div className={classes.preview}>
                <img width="100%" src={props.meta.preview} alt="SNFT Preview"/>
            </div>
          </div>
        <div>
          {props.caps[isdCapsCode.own] ? "YOUR SQARE - YOUR ART" : "NOT YOUR SQARE - NOT YOUR ART"}
        </div>
        <div>
        {  props.status.codes[ isdStatusCode.stage ] < 2 ? " NOT YET TRADABLE " : ( props.status.codes[ isdStatusCode.stage ] > 2 ? " NOT TRADABLE ANYMORE ":"") }
        </div>
      </div>
      <div className={classes.box} >
        <div>
          <p>Artwork</p>
          <Table data={[
              [ "artist", props.meta.properties.art.artist],
              [ "title",  props.meta.properties.art.title],
              [ "year",   props.meta.properties.art.year],
              [ "region", props.meta.properties.art.region],
              [ "type",   props.meta.properties.art.type],
              [ "size",
                props.meta.properties.material.width + "x" + props.meta.properties.material.height +
               (props.meta.properties.material.depth > 0 ? " x " + props.meta.properties.material.height : "")
              ],

                 props.meta.properties.art.edition.total > 0 ?
                  [ "edition",
                       (props.meta.properties.art.edition.special ? "Special Edition: " : "")
                        + " "
                        + props.meta.properties.art.edition.title
                        + " "
                        + props.meta.properties.art.edition.index
                        + " of "
                        + props.meta.properties.art.edition.total
                      ]
                  :
                   [ null ]


          ]} />

          </div>
          <div>
          <p>Media</p>

          <ul>

           {props.metaURI.length > 0 ?
           <li>
           viewer:&nbsp;
             <b><a href={webLink} rel="noopener noreferrer" target="_blank">Web</a></b>
             &nbsp;
             &nbsp;
             <b><a href={arLink} rel="noopener noreferrer" target="_blank">Augmented</a></b>
           </li>
           :
           ""
           }

          { props.meta.external_link ?
            <li><br/>link:  <b><a href={props.meta.external_link} rel="noopener noreferrer" target="_blank">Web</a></b></li>
            :
            ""
          }
          </ul>
          {props.meta.description.length > 0 ?
          <p>
            description: <b>{props.meta.description}</b>
          </p>
          :
          ""
          }

        </div>
        <div>
          <p>Material</p>

          <Table data={[
            [ "type" , props.meta.properties.material.type.join(",") ],

             props.meta.properties.material.weight > 0 ?
              ["weight", props.meta.properties.material.weight ]
              :
              [ null ]

            ,

             props.meta.properties.material.description.length > 0 ?
              ["detailed", props.meta.properties.material.description ]
              :
              [ null ]

          ]} />
        </div>
        <div>
          <p>History</p>
          <ul>
            <li>exhibition: <b>{props.meta.properties.art.exhibition ? props.meta.properties.art.exhibition : "n/a" }</b></li>
            <li>auction: <b>{props.meta.properties.art.auction ? props.meta.properties.art.auction : "n/a" }</b></li>
            <li>owner: <b>{props.meta.properties.art.owner ? props.meta.properties.art.owner : "n/a" }</b></li>
          </ul>
        </div>
        <div>
          <p><br/></p>
        </div>
      </div>
    </div>
  );
};

export default Profile721;
