import React, { useState, useReducer } from 'react';

import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';

import classes from './Offer.module.css';
import stakeIcon from '../assets/stake.png';

import { isdCapsCode, isdCurrency, isdNullPrice } from '../isd.js';



const Offer = (props) => {

  const [inputValue, setInputValue] = useState({ price: "", currency: '0x0000000000000000000000000000000000000000', decimals: 18, from: 0, till: 0 });

  const [inputPrice, setInputPrice] = useState(null);
  const [selectCurrency, setSelectCurrency] = useState({ value: '0x0000000000000000000000000000000000000000', label: 'ETH'});

  const [inputFrom, setInputFrom] = useState(null);
  const [inputTill, setInputTill] = useState(null);


  const [, forceUpdate] = useReducer(x => x + 1, 0);


 const unix2stamp = (s) => {
   if (parseInt(s) === 0) {
     return("n/a");
   } else {
     let dt = new Date(s * 1000);
     return(
           dt.getDate()+
       "/"+(dt.getMonth()+1)+
       "/"+dt.getFullYear()+
       " "+dt.getHours()+
       ":"+dt.getMinutes()+
       ":"+dt.getSeconds()
     );
   }
 }

 const ux = (v) => {
   return v ? Math.floor(v.getTime() / 1000) : 0;
 }

 const handleChange = ( data , d) => {

   console.log("hc: [",d,"]:",data, selectCurrency);

   let i = {
      price: d == 1 ? data : inputPrice,
      currency: d == 2 ? data: selectCurrency.value,
      decimals: d == 2 ? isdCurrency[ data ].decimals : isdCurrency[ selectCurrency.value ].decimals,
      from: d == 3 ?  ux(data) : ux(inputFrom),
      till: d == 4 ?  ux(data) : ux(inputTill)
   };
/*
   if (d==1) setInputPrice(data.target.value);
   if (d==3) setInputFrom(data);
   if (d==4) setInputTill(data);
*/
   props.inputHandler(i);
   // props.inputHandler(i);

   forceUpdate();
 }

/*
  const handleChangePrice = (event) => {

    console.log("hcp",event);
    event.preventDefault();

    let i = {
       price: event.target.value,
       currency: inputValue.currency,
       decimals: isdCurrency[ inputValue.currency ].decimals,
       from: inputFrom ? Math.floor(inputFrom.getTime() / 1000) : 0,
       till: inputTill ? Math.floor(inputTill.getTime() / 1000)  : 0
    };
    setInputValue(i);
    props.inputHandler(i);
    // props.inputHandler(i);

    forceUpdate();
  };
*/


/*
  const handleChangeCurrency = ( c ) => {
//    event.preventDefault();

console.log("hcc",c);

    // currency selection is { value: , label: }
    setSelectCurrency(c);

    let i = {
      price: inputValue.price,
      currency: c.value,
      decimals: isdCurrency[ c.value ].decimals,
      from: inputFrom ? Math.floor(inputFrom.getTime() / 1000) : 0,
      till: inputTill ? Math.floor(inputTill.getTime() / 1000) : 0
    };
    setInputValue(i);
    props.inputHandler(i);

    forceUpdate();
   };

*/


  const options = [];
//  console.log(isdCurrency);
  for (const code in isdCurrency) {
//    console.log("##",isdCurrency[ code ].chain,props.network.id,isdCurrency[ code ]);
    if (isdCurrency[ code ].chain == props.network.id   ||  isdCurrency[ code ].chain == 0 ) {
      options.push({ value: code, label: isdCurrency[ code ].name }  );
    }
  }

/*
  const goMax = () => {
    setInputValue(props.userBalance);
    props.inputHandler(props.userBalance);
  };

*/
//  console.log("offer: ",props.price,isdCurrency,options);

//  onChange={inputChangeHandler}
// onChange={handleChange}

  return (
    <div className={classes.Offer}>

      <p>Price: { props.price.price/ (10**isdCurrency[ props.price.currency ].decimals) + " " +   isdCurrency[ props.price.currency ].name }</p>

      <div className={classes.box}>
        <p>Status</p>
        <ul>
          <li> for Sale since: <b>{props.status.dates[6]}</b></li>
          <li> unLocked: <b>{props.status.dates[0]}</b></li>
          <li> starts: <b>{ props.price.from >0 ? unix2stamp(props.price.from) : 'not for sale' }</b></li>
          <li> finishs: <b>{ props.price.till >0 ? unix2stamp(props.price.till) : 'not in auction' }</b></li>
          <li> currency: <b>{props.price.currency}</b></li>
        </ul>
      </div>

      <div className={classes.inputDiv}>
        <input
          className={classes.input}
          onChange={(event) => {
            console.log("start:",event.target.value);
            setInputPrice(event.target.value);
            handleChange(event.target.value, 1);
          }}
          value={inputPrice}
        ></input>
          <Select
               defaultValue={options[0]}
               className={classes.select}
               onChange={(c) => {
                 console.log("start:",c.value);
                 setSelectCurrency(c);
                 handleChange(c.value, 2);
               }}
               value={selectCurrency}
               options={options}
             />
       </div>
       <div className={classes.inputDiv}>
        Start: <DateTimePicker onChange={(date) => {
          console.log("start:",date);
          setInputFrom(date);
          handleChange(date, 3);
        }} value={inputFrom} className={classes.Picker} maxDate={inputTill}/>
      </div><div  className={classes.box}>
      <p> keep empty if you want to start now </p>
      </div>
      <div className={classes.inputDiv} >
        Finish: <DateTimePicker onChange={(date) => {
          console.log("finish:",date);
          setInputTill(date);
          handleChange(date, 4);
        }} value={inputTill} className={classes.Picker} minDate={inputFrom}/>
      </div>
      <div  className={classes.box}>
        <p>setting an end date will <b>start auction</b> </p>
      </div>

      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.offer] }
        onClick={ () => {
          props.setPrice();
          //setInputValue(isdNullPrice);
        }}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        {
          inputFrom == null ?
          (
          inputTill == null ?
            <p>Start&nbsp;Sale</p>
            :
            <p>Start&nbsp;Auction</p>
          )
          :
          (
          inputTill == null ?
            <p>Set&nbsp;Sale</p>
            :
            <p>Set&nbsp;Auction</p>
          )
        }
      </button>
      &nbsp; &nbsp;
      <button
        className={classes.stakeButton}
        disabled={!props.caps[isdCapsCode.offer]  }
        onClick={() => {
          props.delPrice();
          setInputValue(isdNullPrice);
        }}
      >
        <img src={stakeIcon} alt="stake icon" className={classes.stakeIcon} />
        <p>Stop</p>
      </button>
    </div>
  );
};
//My balance: 504304.394968082 TestToken (Tst)
export default Offer;
